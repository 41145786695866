import Link from 'next/link';
import { Container, Breadcrumb, BreadcrumbItem, Typography, Button } from '@objectedge/pecai-storefront-ds';
import styles from './NotFound.module.scss';
import { getMediaPath } from '~/utils/getPublicPath';

export const NotFound = () => {
  const breadCrumbs: BreadcrumbItem[] = [
    {
      key: 1,
      href: '/',
      name: 'Home',
    },
  ];

  return (
    <div className={styles['not-found']}>
      <Container>
        <Breadcrumb activeName="Erro 404" crumbs={breadCrumbs} listClassName="pl-0" />
        <div className={styles['not-found-wrapper']}>
          <div
            className={styles['not-found-icon']}
            style={{ backgroundImage: `url(${getMediaPath('/media/home/Not-Found.png')})` }}
          ></div>
          <Typography className={styles['not-found-title']} variant="h3" weight='bold' >
            Página não encontrada
          </Typography>

          <Typography className={styles['not-found-body']}>
            {' '}
            A página que você está tentando acessar não existe ou encontra-se indisponível no momento. Por favor, tente
            novamente mais tarde.
            <br />
            <br />
            Agradecemos a sua compreensão.
          </Typography>

          <Link href="/">
            <a>
              <Button className={styles['not-found-button']}>Voltar para o início</Button>
            </a>
          </Link>
        </div>
      </Container>
    </div>
  );
};
