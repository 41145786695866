import Head from 'next/head';
import { MainLayout } from '~/layouts/MainLayout';
import { NotFound } from '~/components/not-found';

export const Custom404 = () => {
  return (
    <MainLayout>
      <Head>
        <title>Página não encontrada | Peça aí</title>
      </Head>
      <NotFound />
    </MainLayout>
  );
};

export default Custom404;
